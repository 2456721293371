import "bootstrap/dist/css/bootstrap.min.css";
import "./src/styles/global.scss";
import "./src/styles/xs.scss";
import "./src/styles/sm.scss";
import "./src/styles/md.scss";
import "./src/styles/lg.scss";
import "./src/styles/x.scss";
import "./src/styles/xl.scss";
import "./src/styles/xxl.scss";
import "./src/styles/xxxl.scss";
import "./src/styles/animation.scss"
import "hamburgers/_sass/hamburgers/hamburgers.scss";
import React from "react"
import ModalContextProvider from './src/context/modalContext';
export const wrapRootElement = ({ element }) => (
  <ModalContextProvider>{element}</ModalContextProvider>
)