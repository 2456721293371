// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/codebuild/output/src279719228/src/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/codebuild/output/src279719228/src/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-how-it-works-js": () => import("/codebuild/output/src279719228/src/src/pages/howItWorks.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src279719228/src/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("/codebuild/output/src279719228/src/src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-type-form-js": () => import("/codebuild/output/src279719228/src/src/pages/typeForm.js" /* webpackChunkName: "component---src-pages-type-form-js" */)
}

