import React, { createContext, useState } from 'react';

export const ModalContext = createContext({show: {}, onHide: {}, step: {}, setStep: {}});

const ModalContextProvider = (props) => {

    // const [step, setStep] = useState(1);

    const [show, setShow] = useState(false);
    
    const onHide = (show) => {

        setShow(show);
        // if (step === 1) {
        //     setShow(show);
        // }
        // else{
        //     setStep(1);
        // }
    }

    // const hideModal = () => {
    //     setShow(false);
    // }

    // return (
    //     <ModalContext.Provider value={{ show, onHide, step, setStep, hideModal}}>
    //         {props.children}
    //     </ModalContext.Provider>
    // );

    return (
        <ModalContext.Provider value={{ show, onHide }}>
            {props.children}
        </ModalContext.Provider>
    );
}
export default ModalContextProvider;