module.exports = [{
      plugin: require('/codebuild/output/src279719228/src/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"black","showSpinner":false},
    },{
      plugin: require('/codebuild/output/src279719228/src/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["proxima nova f1","proxima nova f2","proxima nova f3","proxima nova f4","proxima nova f5","proxima nova f6","proxima nova f7","proxima nova more","interstate","interstate bold"]}},
    },{
      plugin: require('/codebuild/output/src279719228/src/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P45TD6M"},
    },{
      plugin: require('/codebuild/output/src279719228/src/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-154748675-2","head":true},
    },{
      plugin: require('/codebuild/output/src279719228/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
